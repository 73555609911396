<template>
  <div>
    <div class="show_data" style="margin-top:-24px;margin-right: 24px">


      <div
        ref="dashboard"
        class="info"
        style="margin-top:80px;margin-bottom:20px"
      >
        <el-input
          :rules="rules"
          style="margin-left:20px;width: 240px"
          v-model="list_url"
          type="url"
          placeholder="Filter Key Word"
          clearable
          required
        ></el-input>

        <el-button
          type="success"
          style="margin-left:20px"
          @click="generatedCoupon"
          :loading="this.btnLoading"
          >{{ this.btnName }}</el-button
        >

        <div class="button-group" style="margin-top: 12px">
          <el-upload
            action="/api/event_feed/uploadKeywordFile/"
            :headers="{ Authorization: 'JWT ' + this.$store.state.token }"
            :accept="'.xls, .xlsx'"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
          >
            <el-button type="success">Upload Excel File</el-button>
          </el-upload>

<!--          <el-button type="primary" style="margin-left:20px" @click="downloadDB"-->
<!--            >Download Databases</el-button-->
<!--          >-->

<!--          <el-button-->
<!--            type="primary"-->
<!--            style="margin-left:20px"-->
<!--            @click="updateDB"-->
<!--            :loading="this.updateLoading"-->
<!--            >Update Databases</el-button-->
<!--          >-->
        </div>



        <div align="center" style="margin-top: 32px">
          <el-card class="box-card" style="margin-top: 32px;width: 70%">
            <el-table
              :data="customeList"
              default-text="N/A"
              style="width: 100%"
              border
            >
              <el-table-column prop="list_url" label="Keyword" min-width="200">
              </el-table-column>

              <el-table-column
                prop="create_date"
                label="Create Time"
                min-width="200"
              >
              </el-table-column>

              <el-table-column align="center" label="Action" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click.native="deleteRow(scope.row.list_id)"
                  >
                    Delete
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </div>

        <el-dialog
          title="Upload Result"
          :visible.sync="uploadResultDialogVisible"
        >
          <el-table
            ref="uploadTable"
            :data="uploadResult"
            :height="300"
            style="width: 100%"
            :show-header="false"
            stripe
            border
            highlight-current-row
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="value" label="Url"></el-table-column>
          </el-table>
          <el-row style="margin-top: 20px">
            <el-col :span="2">
              <el-checkbox
                v-model="isAllSelected"
                @change="handleAllSelectionChange"
              >
                Select All
              </el-checkbox>
            </el-col>
            <!--        <el-col :span="20" style="text-align: right">-->
            <!--          <el-button @click="dialogVisible = false">取消</el-button>-->
            <!--          <el-button type="primary" @click="handleOk">确定</el-button>-->
            <!--        </el-col>-->


          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="uploadResultDialogVisible = false"
              >Cancel</el-button
            >
            <el-button type="primary" @click="handleOk">Confirm</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadResultDialogVisible: false,
      uploadResult: null,
      isAllSelected: false,
      isAllUnselected: false,
      nameFilter: "keywordlist",
      selectedItems: [],
      list_type: "keywordlist",
      list_url: "",
      customeList: [],
      btnLoading: false,
      updateLoading: false,
      updateDate: "N/A",
      btnName: "Add",
      rules: {
        list_url: [
          {
            required: true,
            message: "Please input a vail Keyword",
            trigger: "blur"
          },
          {
            validator: function(rule, value, callback) {
              if (
                // /^http:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(value) ==
                /^([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(value) == false
              ) {
                this.$notify({
                  title: "Note",
                  message: "Please input a vail URL",
                  type: "warning"
                });
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted: function() {
    this.loadEvents();
  },
  beforeDestroy() {},
  watch: {},
  methods: {
    /* eslint-disable */
                logout() {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$store.commit('clearToken');
                    this.$router.push('/SMTLogin')
                },
    handleUploadSuccess(response, file, fileList) {
                  if (response.result) {
        this.uploadResult = JSON.parse(response.result).map((x) => ({ value: x }));
        this.uploadResultDialogVisible = true;
      } else {
        this.$message.error(response.msg);
      }

    },
    validateInput(text) {
      const englishPattern = /[a-zA-Z]/g;
      const chinesePattern = /[\u4e00-\u9fa5]/g;
      const englishCount = (text.match(englishPattern) || []).length;
      const chineseCount = (text.match(chinesePattern) || []).length;

      // 單獨英文字符時至少為3
      if (englishCount > 0 && chineseCount === 0) {
        return englishCount >= 3;
      }

      // 單獨中文字符時至少為2
      if (chineseCount > 0 && englishCount === 0) {
        return chineseCount >= 2;
      }

      // 中英文混合時，總字符數至少為2
      return (englishCount + chineseCount) >= 2;
    },
    removeHttp(url) {
        if (url.startsWith('http://')) {
            return url.slice(7); // 移除 'http://'（7个字符）
        } else if (url.startsWith('https://')) {
            return url.slice(8); // 移除 'https://'（8个字符）
        } else {
            return url; // 不以 http 或 https 开头时返回原字符串
        }
    },
    handleUploadError(error, file, fileList) {
      this.$message.error(response.msg);
    },
    handleSelectionChange(selection) {
      this.isAllSelected = selection.length === this.uploadResult.length;
    },
    handleAllSelectionChange(value) {
      if (value) {
        this.$refs.uploadTable.toggleAllSelection();
      } else {
        this.$refs.uploadTable.clearSelection();
      }
      this.isAllUnselected = false;
      this.isAllSelected = true;
    },
    handleAllUnselectionChange(value) {
      if (value) {
        this.$refs.uploadTable.clearSelection();
      }
      this.isAllUnselected = true;
      this.isAllSelected = false;
    },
    handleOk() {
      const selectedItems = this.$refs.uploadTable.selection;
      this.$message.info(`You added ${selectedItems.length} records`);

      if (selectedItems === undefined ||selectedItems.length <1){

        this.uploadResultDialogVisible = false;

      }else{

        var that = this;
        this.$axios.post('/api/event_feed/addKeywordList/', {
                            list_type: "keywordlist",
                            add_type: "list",
                            list_url: JSON.stringify(selectedItems)
                        }, {
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
        // this.$axios.get('/api/event_feed/addCustomList/', {
        //                     params: {
        //                        list_type:this.nameFilter,
        //                         add_type:"list",
        //                         list_url:JSON.stringify(selectedItems)
        //                     },
        //                     headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        //                 })
                        .then((res) => {

                            that.uploadResultDialogVisible = false;
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                  this.list_url = ''

                                  this.customeList = result['data']

                                  this.updateDate = result['date']



                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            that.uploadResultDialogVisible = false;
                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                        })

      }


    },
                generatedCoupon() {

                    if (this.list_type == '' || this.list_type == 'n/a'){

                        this.$notify({
                          title: 'Note',
                          message: 'Please select a SMS type.',
                          type: 'warning'
                        });

                        return;

                    }

                    if (this.list_url == ''){

                        this.$notify({
                          title: 'Note',
                          message: 'Please input Keyword.',
                          type: 'warning'
                        });

                        return;

                    }

                    if (this.validateInput(this.list_url)) {


                    }else{

                       this.$notify({
                          title: "Note",
                          message: "Please input a vail Keyword",
                          type: "warning"
                        });

                        return;
                    }

                    var that = this;
                    this.btnLoading = true;
                    console.log("add records.....")
                    this.$axios.post('/api/event_feed/addKeywordList/', {
                                add_type: "single",
                                list_type: this.list_type,
                                list_url: this.list_url
                            }, {
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                    })
                    // this.$axios.get('/api/event_feed/addCustomList/', {
                    //         params: {
                    //            list_type:this.list_type,
                    //             list_url:this.list_url
                    //         },
                    //         headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                    //     })
                        .then((res) => {

                            that.btnLoading = false;

                            if (res.status === 401) {


                              alert('Login timed out, please log in again')

                              that.logout()

                            }

                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                  this.list_url = ''

                                  this.customeList = result['data']

                                  this.updateDate = result['date']



                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            that.btnLoading = false;
                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                        })

                },
            updateDB() {

                  this.updateLoading = true;
                  var that = this;
              this.$axios.get('/api/event_feed/updateCustomList/', {
                            params: {},
                            timeout: 600000,
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {

                          that.updateLoading = false;
                          if (res.status === 401) {


                              alert('Login timed out, please log in again')

                              that.logout()

                            }

                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                     this.$notify({
                          title: 'Note',
                          message: 'The server has started to update the database, usually the update will be completed after 5 minutes',
                          type: 'warning'
                        });

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {

                          that.updateLoading = false;

                          if (arg.response && arg.response.status === 502) {
                                // 处理 502 错误的代码...

                                  that.$notify({
                                title: 'Note',
                                message: 'The server has started to update the database, usually the update will be completed after 5 minutes',
                                type: 'warning'
                              });

                              } else {
                                // 处理其他错误的代码...

                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                          }



                        })

    },
    downloadDB() {

              this.$axios.get('/api/event_feed/downloadCustomList/', {
                            params: {},
                            responseType: 'blob',
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/x-www-form-urlencoded' }

                        })
                        .then((response) => {

                          if (response.status === 401) {


                              alert('Login timed out, please log in again')

                              that.logout()

                            }

                          let filename = response.headers['content-disposition'].match(/filename=(.*)/)[1]
                                  // 将二进制流转为blob
                                  const blob = new Blob([response.data], {type: response.headers['Content-Type']})
                                  if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
                                    window.navigator.msSaveBlob(blob, decodeURI(filename))

                                  } else {
                                    // 创建新的URL并指向File对象或者Blob对象的地址
                                    const blobURL = window.URL.createObjectURL(blob)
                                    // 创建a标签，用于跳转至下载链接
                                    const tempLink = document.createElement('a')
                                    tempLink.style.display = 'none'
                                    tempLink.href = blobURL
                                    tempLink.setAttribute('download', decodeURI(filename))
                                    // 兼容：某些浏览器不支持HTML5的download属性
                                    if (typeof tempLink.download === 'undefined') {
                                      tempLink.setAttribute('target', '_blank')
                                    }
                                    // 挂载a标签
                                    document.body.appendChild(tempLink)
                                    tempLink.click()
                                    document.body.removeChild(tempLink)
                                    // 释放blob URL地址
                                    window.URL.revokeObjectURL(blobURL)

                                  }

                        }).catch(function(arg) {

                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                        })

    },
        deleteRow(row) {
      this.$confirm('Do you confrim delete the item?', 'Messages', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/event_feed/delKeywordList/', {
                            params: {list_id:row},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                    this.customeList = result['data']
                                    this.updateDate = result['date']

                                    // this.reload()

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Unknown Error'
        })
      })
    },
                loadEvents() {
                    var that = this;
                    this.$axios.get('/api/event_feed/getKeywordList/', {
                            params: {},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                    this.customeList = result['data']
                                    this.updateDate = result['date']
                                    

                                    // this.reload()

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            if (arg.response && arg.response.status === 401) {

                              alert('Login timed out, please log in again')
                              that.logout()

                            }else{

                              alert('Unknown Error, please try again : '+arg)
                              that.logout()

                            }

                        })
                }
            }

        }
        </script>

        <style scoped>
        h1,
        h2 {
            font-weight: normal;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            display: inline-block;
            margin: 0 10px;
        }

        a {
            color: #42b983;
        }

        .el-table .warning-row {
          background: #42b983;
        }

        .el-table .success-row {
          background: #f0f9eb;
        }
        .text {
          font-size: 14px;
        }

        .item {
          margin-bottom: 18px;
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }
        .clearfix:after {
          clear: both
        }

        .box-card {
          width: 720px;
        }
        .button-group {
  display: flex;
  justify-content: center;
}
.button-group > * {
  margin: 0 10px;
}
        </style>
